import * as React from "react"
import Seo from "../../components/seo"
import BrandHeading from "../../components/brand-heading";
import { DietaryRequirementsSection } from "../../components/dietary-options";
import { usePetiteCakes } from "../../hooks/use-petite-cakes";
import { usePrices } from "../../hooks/use-prices";
import { Link } from "gatsby";
import Cake from "../../components/cake";

export default function PetiteCakes() {
    const prices = usePrices();
    const products = usePetiteCakes().sort((x, y) => x.metadata.sort - y.metadata.sort);
    const cakes = products.map(cake => {
        const cakePrices = prices.filter(x => x.product.id === cake.id);
        cake = {
            ...cake
            , code: cake.metadata.code
            , subType: cake.metadata.subType
            , imageFile: cake.localImage[0]
            , options: cakePrices.map(x => { return { id: x.id, size: x.description, price: (x.unit_amount) / 100 }; })
        }
        return <Link key={cake.code} to={`/cakes/${cake.subType}/${cake.code}`}>
            <Cake cake={cake} />
        </Link>
    })
    return (
        <>
            <Seo title="Petite Cakes" />
            <BrandHeading title="petite cakes" />
            <section>
                <DietaryRequirementsSection className="mb-10 text-center text-sm md:text-base" />
                <div className="flex flex-wrap justify-evenly">
                    {cakes}
                </div>
            </section>
        </>
    )
}